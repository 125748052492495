import React from 'react';

import type { Event } from '@fie/analytics-library/src/types/Analytics';

import buildUrl from '../../../../utils/buildUrl';
import { getBaseCCUrl, getCCParams } from '../../../modules/getCCUrl';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../TrackedLink/TrackedLink';

import type { IAllDealsProps } from './model';
import styles from './styles/all-deals.css';

const AllDeals: React.FC<IAllDealsProps> = ({ deal, className, children, params }) => {
  const {
    site,
    platform,
    language,
    url: urlPath,
    articleId,
    sendAnalytics,
    allDealsLink,
    genericSharedComponents: { TrackedLink },
  } = useHawkWidgetContext();

  // TODO: We should refactor this to not return boolean
  const getUrl = (): string | boolean => {
    const baseUrl = getBaseCCUrl(site, platform, language, urlPath);

    if (baseUrl) {
      // Set the origin based on the url & document
      // Build url params
      const newParams = {
        ...(params || {}),
        origin: ((): string => {
          if (
            /\/review/.test(urlPath) ||
            (typeof document !== 'undefined' && document.querySelector('article.review-article'))
          ) {
            return 'review';
          }
          if (typeof document !== 'undefined' && document.querySelector('#wrapper-gallery')) {
            return 'gallery';
          }
          if (/\/news\/.*-(\d+).*$/.test(urlPath)) {
            return 'news';
          }
          if (typeof document !== 'undefined' && document.querySelector('article.buyingguide')) {
            return 'buyingguide';
          }
          if (/^\/tuition\/.*\d+$/.test(urlPath)) {
            return 'tuition';
          }
          if (/\/features\//.test(urlPath)) {
            return 'features';
          }
          return '';
        })(),
      };
      return buildUrl(baseUrl, getCCParams(newParams, articleId));
    }
    return false;
  };

  const trackLinkClick = (event): void => {
    if (sendAnalytics) {
      sendAnalytics(
        {
          clickType: 'CC',
          prefix: 'Click from',
        } as Event,
        deal || null,
        event,
      );
    }
  };

  const url = getUrl();

  const hrefAttribute = allDealsLink || url;

  if (url) {
    return (
      <TrackedLink
        trackLinkClick={trackLinkClick}
        customTarget={TargetEnum.SELF}
        attributes={{
          className: className && styles[className],
          ...(hrefAttribute && typeof hrefAttribute === 'string' ? { href: hrefAttribute } : {}),
        }}
      >
        {children}
      </TrackedLink>
    );
  }
  return null;
};

export default AllDeals;
