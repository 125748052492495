import type { Deal } from '../../../types/Deal';

export default (deal: Deal, articleUrl: string, clickType?: string): string => {
  if (clickType) {
    return clickType;
  }
  if (deal?.product_type && deal.product_type === 200) {
    return 'Check retailer';
  }

  // Check if link is internal
  try {
    const domain = new URL(deal.offer.link);

    if (articleUrl.includes(domain?.hostname)) {
      return 'internal';
    }
  } catch (_) {
    if (deal.offer.link.startsWith('/')) {
      return 'internal';
    }
  }

  return 'retailer';
};
