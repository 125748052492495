export const getGATitle = (productType: number): string => {
  switch (productType) {
    case 3500:
      return 'Multimedia';
    case 3000:
      return 'Broadband';
    case 2500:
      return 'Subscription';
    case 2000:
      return 'Phone';
    case 1000:
      return 'Retail';
    case 400:
      return 'Offers';
    case 410:
      return 'Sales';
    case 420:
      return 'Deals';
    case 425:
      return 'Newsletter';
    case 430:
      return 'Vouchers';
    case 431:
      return 'UniqueCode';
    case 300:
      return 'Fallback';
    case 200:
      return 'Similar';
    default:
      return '';
  }
};
