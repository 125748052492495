import loadable, { LoadableComponent } from '@loadable/component';

import { WidgetId } from '../core/types/Widget';

const getComponent = async (
  widgetTypeComponent: WidgetId,
  // Needs to be any as every widget will have its own props interface
): Promise<LoadableComponent<any> | null> => {
  switch (widgetTypeComponent) {
    case WidgetId.BESTOF:
      return loadable(
        () => import(/* webpackChunkName: 'bestof' */ '../core/components/BestOf/BestOf'),
      );
    case WidgetId.BUNDLE:
      return loadable(
        () =>
          import(/* webpackChunkName: 'bundle' */ '../core/components/BundleWidget/BundleWidget'),
      );
    case WidgetId.BUTTON:
      return loadable(
        () =>
          import(/* webpackChunkName: 'button' */ '../core/components/ButtonWidget/ButtonWidget'),
      );
    case WidgetId.COMPARISON:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'comparison' */ '../core/components/Comparison/Main/Comparison'
          ),
      );
    case WidgetId.DEAL:
      return loadable(
        () => import(/* webpackChunkName: 'deal' */ '../core/components/DealWidget/DealWidget'),
      );
    case WidgetId.DOTW:
      return loadable(
        () =>
          import(/* webpackChunkName: 'dotw' */ '../core/components/DealOfTheWeek/DealOfTheWeek'),
      );
    case WidgetId.EDITORS_PICK:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'editorspick' */ '../core/components/EditorsPick/EditorsPick'
          ),
      );
    case WidgetId.TABS:
      return loadable(
        () => import(/* webpackChunkName: 'tabs' */ '../core/components/TabsWidget/TabsWidget'),
      );
    case WidgetId.GENERIC_FALLBACK:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'genericfallback' */ '../core/components/GenericFallback/GenericFallback'
          ),
      );
    case WidgetId.INBODY:
      return loadable(
        () => import(/* webpackChunkName: 'inbody' */ '../core/components/InBody/InBody'),
      );
    case WidgetId.LABELS:
      return loadable(
        () =>
          import(/* webpackChunkName: 'labels' */ '../core/components/LabelsWidget/LabelsWidget'),
      );
    case WidgetId.LINK:
      return loadable(
        () => import(/* webpackChunkName: 'link' */ '../core/components/LinkWidget/LinkWidget'),
      );
    case WidgetId.MERCHANT_LINK:
      return loadable(() => {
        return import(
          /* webpackChunkName: 'merchantlink' */ '../core/components/MerchantLinkWidget/MerchantLinkWidget'
        );
      });
    case WidgetId.MAGAZINE_SUBSCRIPTIONS:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'magazinesubscriptions' */ '../core/components/MagazineSubscriptions/MagazineSubscriptions'
          ),
      );
    case WidgetId.MERCHANT_OVERVIEW:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'merchantoverview' */ '../core/components/MerchantOverview/MerchantOverview'
          ),
      );
    case WidgetId.MONA_WIDGET:
      return loadable(
        () =>
          import(/* webpackChunkName: 'monawidget' */ '../core/components/MonaWidget/MonaWidget'),
      );
    case WidgetId.MOST_POPULAR_OFFERS:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'mostpopularoffers' */ '../core/components/MostPopularOffers/MostPopularOffers'
          ),
      );
    case WidgetId.MULTI_MODEL_REVIEW:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'multimodelreview' */ '../core/components/MultiModelReview/Main/MultiModelReview'
          ),
      );
    case WidgetId.OVERLAY:
      return loadable(
        () => import(/* webpackChunkName: 'overlay' */ '../core/components/Overlay/Overlay'),
      );
    case WidgetId.PARTNER:
      return loadable(
        () => import(/* webpackChunkName: 'partner' */ '../core/components/Partner/Partner'),
      );
    case WidgetId.PEACOCK:
      return loadable(
        () => import(/* webpackChunkName: 'peacock' */ '../core/components/Peacock/Peacock'),
      );
    case WidgetId.POCKET:
      return loadable(
        () => import(/* webpackChunkName: 'pocket' */ '../core/components/Pocket/Main/Pocket'),
      );
    case WidgetId.PRICE:
      return loadable(
        () => import(/* webpackChunkName: 'price' */ '../core/components/Price/Main/Price'),
      );
    case WidgetId.PRODUCT_BLOCK:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'productblock' */ '../core/components/ProductBlock/ProductBlock'
          ),
      );
    case WidgetId.PRODUCT_NAME:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'productname' */ '../core/components/ProductName/Main/ProductName'
          ),
      );
    case WidgetId.PROMOTION:
      return loadable(
        () => import(/* webpackChunkName: 'promotion' */ '../core/components/Promotion/Promotion'),
      );
    case WidgetId.PROMOTION_FALLBACK:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'promotionfallback' */ '../core/components/Promotion/PromotionFallback'
          ),
      );
    case WidgetId.REVIEW:
      return loadable(
        () => import(/* webpackChunkName: 'review' */ '../core/components/Review/Main/Review'),
      );
    case WidgetId.SCROLLABLE_TILE:
      return loadable(
        () => import(/* webpackChunkName: 'scrollable-tile' */ '../core/components/ScrollableTile'),
      );
    case WidgetId.SEASONAL:
      return loadable(
        () => import(/* webpackChunkName: 'seasonal' */ '../core/components/Seasonal/Seasonal'),
      );
    case WidgetId.SHOP:
      return loadable(() => import(/* webpackChunkName: 'shop' */ '../core/components/Shop/Shop'));
    case WidgetId.SIMPLE:
      return loadable(
        () => import(/* webpackChunkName: 'simple' */ '../core/components/Simple/Main/Simple'),
      );
    case WidgetId.SPECS:
      return loadable(
        () => import(/* webpackChunkName: 'specs' */ '../core/components/Specs/Specs'),
      );
    case WidgetId.STICKY:
      return loadable(
        () => import(/* webpackChunkName: 'sticky' */ '../core/components/Sticky/Sticky'),
      );
    case WidgetId.STREAMING:
      return loadable(
        () => import(/* webpackChunkName: 'streaming' */ '../core/components/Streaming/Streaming'),
      );
    case WidgetId.TILE:
      return loadable(() => import(/* webpackChunkName: 'tile' */ '../core/components/Tile/Tile'));
    case WidgetId.USER_REVIEWS:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'userreviews' */ '../core/components/UserReviews/UserReviews'
          ),
      );
    case WidgetId.VULTURE:
      return loadable(
        () => import(/* webpackChunkName: 'vulture' */ '../core/components/Vulture/Vulture'),
      );
    case WidgetId.NIGHTHAWK:
      return loadable(
        () => import(/* webpackChunkName: 'nighthawk' */ '../core/components/Nighthawk/Nighthawk'),
      );
    case WidgetId.BROADBAND:
      return loadable(
        () =>
          import(
            /* webpackChunkName: 'broadband' */ '../core/components/BroadbandWidget/BroadbandWidget'
          ),
      );
    default:
      return null;
  }
};

export default async (widgetTypeComponent: WidgetId): Promise<LoadableComponent<any> | null> =>
  getComponent(widgetTypeComponent);
