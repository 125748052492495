import React from 'react';

import { getDealImage } from '../../../modules/getDealImage';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import type { DealImageProps } from './model';

const DealImage: React.FC<DealImageProps> = ({ deal, className, priority, width, height }) => {
  const { url, alt, type } = getDealImage(deal, priority, width);
  const {
    genericSharedComponents: { AffiliateLink, LazyImage },
  } = useHawkWidgetContext();

  const isLogo = ['merchant', 'network'].includes(type);

  return (
    <AffiliateLink deal={deal} className={className}>
      <LazyImage
        src={url}
        alt={alt}
        width={width || (isLogo ? 80 : 78)}
        height={height || (isLogo ? 40 : 70)}
        className={className ?? (isLogo ? 'logo-image' : 'product-image')}
      />
    </AffiliateLink>
  );
};

export default DealImage;
