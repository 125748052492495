import type { AnalyticsData, Event } from '@fie/analytics-library/src/types/Analytics';
import type { EventBattle } from '@fie/analytics-library/src/types/Battle';
import type { Product } from '@fie/analytics-library/src/types/Product';

import type { WidgetFeatures } from '../../modules/features';
import type { Site } from '../../modules/sites/types/Site';
import type { APIData } from '../../types/APIData';
import type { BattleDealOverrides } from '../../types/BattleDealOverrides';
import type { Deal } from '../../types/Deal';
import type { DealData } from '../../types/DealData';
import type { ReviewData } from '../../types/ModelInfo';
import type { TabData } from '../../types/TabData';
import type { Widget, WidgetId } from '../../types/Widget';

export enum AnalyticsAttributes {
  WIDGET_ID = 'widgetId',
  EDITORIAl = 'editorial',
  NAME = 'name',
  ARTICLE_ID = 'articleId',
  AREA = 'area',
  REVIEWS = 'reviews',
  BATTLE = 'battle',
  PLACEHOLDER = 'placeholder',
  LANGUAGE = 'language',
  STAR = 'star',
  VIEWPORT_TIME = 'viewportTime',
  VIEWPORT_ENTER_TIME = 'viewportEnterTime',
  SEND_VIEWED_EVENT = 'sendViewedEvent',
  WIDGET_VIEWED = 'widgetViewed',
  DEAL_DATA = 'dealData',
  WIDGET = 'widget',
  SITE = 'site',
  TYPE = 'type',
  WIDGET_TYPE_COMPONENT = 'widgetTypeComponent',
  DATA = 'data',
  DEFAULT_TAB = 'defaultTab',
  GET_DEAL_DATA = 'getDealData',
}

export interface IAnalytics {
  [AnalyticsAttributes.WIDGET_ID]: string;
  [AnalyticsAttributes.EDITORIAl]: boolean;
  [AnalyticsAttributes.NAME]: string;
  [AnalyticsAttributes.ARTICLE_ID]: string;
  [AnalyticsAttributes.REVIEWS]: ReviewData[];
  [AnalyticsAttributes.BATTLE]: EventBattle | null;
  [AnalyticsAttributes.PLACEHOLDER]: HTMLElement | null;
  [AnalyticsAttributes.LANGUAGE]: string;
  [AnalyticsAttributes.STAR]: string | null;
  [AnalyticsAttributes.DEAL_DATA]: DealData | null;
  [AnalyticsAttributes.WIDGET]: Widget;
  [AnalyticsAttributes.SITE]: Site;
  [AnalyticsAttributes.TYPE]: string;
  [AnalyticsAttributes.WIDGET_TYPE_COMPONENT]: WidgetId;
  [AnalyticsAttributes.DATA]: TabData | null;
  [AnalyticsAttributes.DEFAULT_TAB]: WidgetFeatures[AnalyticsAttributes.DEFAULT_TAB];
  [AnalyticsAttributes.GET_DEAL_DATA]: (
    data: APIData,
    overrides?: BattleDealOverrides[],
  ) => Promise<DealData>;
  [AnalyticsAttributes.AREA]: string | null;
  [AnalyticsAttributes.VIEWPORT_TIME]: number;
  [AnalyticsAttributes.VIEWPORT_ENTER_TIME]: number | null;
  [AnalyticsAttributes.SEND_VIEWED_EVENT]?: boolean;
  [AnalyticsAttributes.WIDGET_VIEWED]?: boolean;
}

export interface IAnalyticsMethods {
  sendAnalytics: (
    event: Event,
    deal?: Deal | null,
    uiEvent?: React.SyntheticEvent<HTMLElement> | null,
    product?: Product | null,
  ) => void;
  getAnalyticsData: () => AnalyticsData;
  setDealData: (dealData: DealData) => void;
  modifiedGetDealData: (data: APIData, overrides?: BattleDealOverrides[]) => Promise<DealData>;
  onViewportLeave: () => void;
  onViewportEnter: () => void;
}

export interface IUseAnalytics extends IAnalyticsMethods {
  analytics: React.MutableRefObject<IAnalytics>;
  inFocus: boolean;
}

export interface IPostProcessData {
  placeholder: HTMLElement | null;
  widgetId: string;
  widgetTypeComponent: WidgetId;
  data: TabData | null;
  dealData: DealData | null;
  type: string;
  sendViewedEvent: IAnalytics['sendViewedEvent'];
}

export interface IPostProcessActions {
  sendAnalytics: IAnalyticsMethods['sendAnalytics'];
  setDealData: IAnalyticsMethods['setDealData'];
}

export enum WidgetResult {
  SKIPPED = 'skipped',
  RENDERED = 'rendered',
  PRESERVED = 'preserved',
  MISSING = 'missing',
}

export enum ApiCallResult {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
