import React from 'react';

import { Site } from '../../modules/sites/types/Site';

import { AdvntureSiteWrapper } from './AdvntureSiteWrapper/loadable';
import { AnandtechSiteWrapper } from './AnandtechSiteWrapper/loadable';
import { AndroidcentralSiteWrapper } from './AndroidcentralSiteWrapper/loadable';
import { AvnetworkSiteWrapper } from './AvnetworkSiteWrapper/loadable';
import { CbqSiteWrapper } from './CbqSiteWrapper/loadable';
import { CinemablendSiteWrapper } from './CinemablendSiteWrapper/loadable';
import { CnSiteWrapper } from './CnSiteWrapper/loadable';
import { CoachmagSiteWrapper } from './CoachmagSiteWrapper/loadable';
import { CountrylifeSiteWrapper } from './CountrylifeSiteWrapper/loadable';
import { CwSiteWrapper } from './CwSiteWrapper/loadable';
import { DcwSiteWrapper } from './DcwSiteWrapper/loadable';
import { DecanterSiteWrapper } from './DecanterSiteWrapper/loadable';
import { DefaultSiteWrapper } from './DefaultSiteWrapper/loadable';
import { FitandwellSiteWrapper } from './FitandwellSiteWrapper/loadable';
import { GardeningetcSiteWrapper } from './GardeningetcSiteWrapper/loadable';
import { GardeningKnowHowSiteWrapper } from './GardeningKnowHowSiteWrapper/loadable';
import { GolfmSiteWrapper } from './GolfmSiteWrapper/loadable';
import { GoodtoSiteWrapper } from './GoodtoSiteWrapper/loadable';
import { GrdSiteWrapper } from './GrdSiteWrapper/loadable';
import { GuitarworldSiteWrapper } from './GuitarworldSiteWrapper/loadable';
import { HomesandgardensSiteWrapper } from './HomesandgardensSiteWrapper/loadable';
import { HorseandhoundSiteWrapper } from './HorseandhoundSiteWrapper/loadable';
import { IdealhSiteWrapper } from './IdealhSiteWrapper/loadable';
import { ImoreSiteWrapper } from './ImoreSiteWrapper/loadable';
import { ItproportalSiteWrapper } from './ItproportalSiteWrapper/loadable';
import { ItproSiteWrapper } from './ItproSiteWrapper/loadable';
import { KplSiteWrapper } from './KplSiteWrapper/loadable';
import { LoudersoundSiteWrapper } from './LoudersoundSiteWrapper/loadable';
import { LtmSiteWrapper } from './LtmSiteWrapper/loadable';
import { LvgSiteWrapper } from './LvgSiteWrapper/loadable';
import { MarieclaireukSiteWrapper } from './MarieclaireukSiteWrapper/loadable';
import { MarieclaireusshopSiteWrapper } from './MarieclaireusshopSiteWrapper/loadable';
import { MarieclaireusSiteWrapper } from './MarieclaireusSiteWrapper/loadable';
import { MbrSiteWrapper } from './MbrSiteWrapper/loadable';
import { MbySiteWrapper } from './MbySiteWrapper/loadable';
import { MoneyweekSiteWrapper } from './MoneyweekSiteWrapper/loadable';
import { MozoSiteWrapper } from './MozoSiteWrapper/loadable';
import { MrdSiteWrapper } from './MrdSiteWrapper/loadable';
import { MyimperfectlifeSiteWrapper } from './MyimperfectlifeSiteWrapper/loadable';
import { MyvouchercodesSiteWrapper } from './MyvouchercodesSiteWrapper/loadable';
import { PboSiteWrapper } from './PboSiteWrapper/loadable';
import { PcgSiteWrapper } from './PcgSiteWrapper/loadable';
import { PetsradarSiteWrapper } from './PetsradarSiteWrapper/loadable';
import { PracticalcaravanSiteWrapper } from './PracticalcaravanSiteWrapper/loadable';
import { PracticalmotorhomeSiteWrapper } from './PracticalmotorhomeSiteWrapper/loadable';
import { RealhomesSiteWrapper } from './RealhomesSiteWrapper/loadable';
import { RugbyworldSiteWrapper } from './RugbyworldSiteWrapper/loadable';
import { ShortlistSiteWrapper } from './ShortlistSiteWrapper/loadable';
import { SpaceSiteWrapper } from './SpaceSiteWrapper/loadable';
import { T3SiteWrapper } from './T3SiteWrapper/loadable';
import { TechlearningSiteWrapper } from './TechlearningSiteWrapper/loadable';
import { TgSiteWrapper } from './TgSiteWrapper/loadable';
import { ThemoneyeditSiteWrapper } from './ThemoneyeditSiteWrapper/loadable';
import { TheweekukSiteWrapper } from './TheweekukSiteWrapper/loadable';
import { TheweekusSiteWrapper } from './TheweekusSiteWrapper/loadable';
import { ThSiteWrapper } from './ThSiteWrapper/loadable';
import { TrdSiteWrapper } from './TrdSiteWrapper/loadable';
import { WallpaperSiteWrapper } from './WallpaperSiteWrapper/loadable';
import { WhathifiSiteWrapper } from './WhathifiSiteWrapper/loadable';
import { WhattowatchSiteWrapper } from './WhattowatchSiteWrapper/loadable';
import { WhowhatwearSiteWrapper } from './WhowhatwearSiteWrapper/loadable';
import { WhSiteWrapper } from './WhSiteWrapper/loadable';
import { WindowscentralSiteWrapper } from './WindowscentralSiteWrapper/loadable';
import { YachtingmonthlySiteWrapper } from './YachtingmonthlySiteWrapper/loadable';
import { YachtingworldSiteWrapper } from './YachtingworldSiteWrapper/loadable';
import { YbwSiteWrapper } from './YbwSiteWrapper/loadable';

export const SiteWrapper: React.FC<{ children: React.ReactNode; site: Site }> = ({
  site,
  children,
}) => {
  switch (site.id) {
    case 'countrylife': {
      return <CountrylifeSiteWrapper>{children}</CountrylifeSiteWrapper>;
    }
    case 'decanter': {
      return <DecanterSiteWrapper>{children}</DecanterSiteWrapper>;
    }
    case 'horseandhound': {
      return <HorseandhoundSiteWrapper>{children}</HorseandhoundSiteWrapper>;
    }
    case 'mbr': {
      return <MbrSiteWrapper>{children}</MbrSiteWrapper>;
    }
    case 'mby': {
      return <MbySiteWrapper>{children}</MbySiteWrapper>;
    }
    case 'pbo': {
      return <PboSiteWrapper>{children}</PboSiteWrapper>;
    }
    case 'rugbyworld': {
      return <RugbyworldSiteWrapper>{children}</RugbyworldSiteWrapper>;
    }
    case 'yachtingmonthly': {
      return <YachtingmonthlySiteWrapper>{children}</YachtingmonthlySiteWrapper>;
    }
    case 'yachtingworld': {
      return <YachtingworldSiteWrapper>{children}</YachtingworldSiteWrapper>;
    }
    case 'ybw': {
      return <YbwSiteWrapper>{children}</YbwSiteWrapper>;
    }
    case 'shortlist': {
      return <ShortlistSiteWrapper>{children}</ShortlistSiteWrapper>;
    }
    case 'advnture': {
      return <AdvntureSiteWrapper>{children}</AdvntureSiteWrapper>;
    }
    case 'androidcentral': {
      return <AndroidcentralSiteWrapper>{children}</AndroidcentralSiteWrapper>;
    }
    case 'avnetwork': {
      return <AvnetworkSiteWrapper>{children}</AvnetworkSiteWrapper>;
    }
    case 'cinemablend': {
      return <CinemablendSiteWrapper>{children}</CinemablendSiteWrapper>;
    }
    case 'cbq': {
      return <CbqSiteWrapper>{children}</CbqSiteWrapper>;
    }
    case 'cn': {
      return <CnSiteWrapper>{children}</CnSiteWrapper>;
    }
    case 'coachmag': {
      return <CoachmagSiteWrapper>{children}</CoachmagSiteWrapper>;
    }
    case 'dcw': {
      return <DcwSiteWrapper>{children}</DcwSiteWrapper>;
    }
    case 'gardeningknowhow': {
      return <GardeningKnowHowSiteWrapper>{children}</GardeningKnowHowSiteWrapper>;
    }
    case 'grd': {
      return <GrdSiteWrapper>{children}</GrdSiteWrapper>;
    }
    case 'guitarworld': {
      return <GuitarworldSiteWrapper>{children}</GuitarworldSiteWrapper>;
    }
    case 'imore': {
      return <ImoreSiteWrapper>{children}</ImoreSiteWrapper>;
    }
    case 'itpro': {
      return <ItproSiteWrapper>{children}</ItproSiteWrapper>;
    }
    case 'itproportal': {
      return <ItproportalSiteWrapper>{children}</ItproportalSiteWrapper>;
    }
    case 'ltm': {
      return <LtmSiteWrapper>{children}</LtmSiteWrapper>;
    }
    case 'loudersound': {
      return <LoudersoundSiteWrapper>{children}</LoudersoundSiteWrapper>;
    }
    case 'mrd': {
      return <MrdSiteWrapper>{children}</MrdSiteWrapper>;
    }
    case 'pcg': {
      return <PcgSiteWrapper>{children}</PcgSiteWrapper>;
    }
    case 'petsradar': {
      return <PetsradarSiteWrapper>{children}</PetsradarSiteWrapper>;
    }
    case 'space': {
      return <SpaceSiteWrapper>{children}</SpaceSiteWrapper>;
    }
    case 'techlearning': {
      return <TechlearningSiteWrapper>{children}</TechlearningSiteWrapper>;
    }
    case 'tg': {
      return <TgSiteWrapper>{children}</TgSiteWrapper>;
    }
    case 'th': {
      return <ThSiteWrapper>{children}</ThSiteWrapper>;
    }
    case 'trd': {
      return <TrdSiteWrapper>{children}</TrdSiteWrapper>;
    }
    case 'whathifi': {
      return <WhathifiSiteWrapper>{children}</WhathifiSiteWrapper>;
    }
    case 'whattowatch': {
      return <WhattowatchSiteWrapper>{children}</WhattowatchSiteWrapper>;
    }
    case 'windowscentral': {
      return <WindowscentralSiteWrapper>{children}</WindowscentralSiteWrapper>;
    }
    case 'anandtech': {
      return <AnandtechSiteWrapper>{children}</AnandtechSiteWrapper>;
    }
    case 'cw': {
      return <CwSiteWrapper>{children}</CwSiteWrapper>;
    }
    case 'fitandwell': {
      return <FitandwellSiteWrapper>{children}</FitandwellSiteWrapper>;
    }
    case 'gardeningetc': {
      return <GardeningetcSiteWrapper>{children}</GardeningetcSiteWrapper>;
    }
    case 'golfm': {
      return <GolfmSiteWrapper>{children}</GolfmSiteWrapper>;
    }
    case 'goodto': {
      return <GoodtoSiteWrapper>{children}</GoodtoSiteWrapper>;
    }
    case 'homesandgardens': {
      return <HomesandgardensSiteWrapper>{children}</HomesandgardensSiteWrapper>;
    }
    case 'idealh': {
      return <IdealhSiteWrapper>{children}</IdealhSiteWrapper>;
    }
    case 'kpl': {
      return <KplSiteWrapper>{children}</KplSiteWrapper>;
    }
    case 'lvg': {
      return <LvgSiteWrapper>{children}</LvgSiteWrapper>;
    }
    case 'marieclaireuk': {
      return <MarieclaireukSiteWrapper>{children}</MarieclaireukSiteWrapper>;
    }
    case 'marieclaireusshop': {
      return <MarieclaireusshopSiteWrapper>{children}</MarieclaireusshopSiteWrapper>;
    }
    case 'marieclaireus': {
      return <MarieclaireusSiteWrapper>{children}</MarieclaireusSiteWrapper>;
    }
    case 'myimperfectlife': {
      return <MyimperfectlifeSiteWrapper>{children}</MyimperfectlifeSiteWrapper>;
    }
    case 'myvouchercodes': {
      return <MyvouchercodesSiteWrapper>{children}</MyvouchercodesSiteWrapper>;
    }
    case 'realhomes': {
      return <RealhomesSiteWrapper>{children}</RealhomesSiteWrapper>;
    }
    case 'themoneyedit': {
      return <ThemoneyeditSiteWrapper>{children}</ThemoneyeditSiteWrapper>;
    }
    case 't3': {
      return <T3SiteWrapper>{children}</T3SiteWrapper>;
    }
    case 'wh': {
      return <WhSiteWrapper>{children}</WhSiteWrapper>;
    }
    case 'wallpaper': {
      return <WallpaperSiteWrapper>{children}</WallpaperSiteWrapper>;
    }
    case 'whowhatwear': {
      return <WhowhatwearSiteWrapper>{children}</WhowhatwearSiteWrapper>;
    }
    case 'theweekuk': {
      return <TheweekukSiteWrapper>{children}</TheweekukSiteWrapper>;
    }
    case 'theweekus': {
      return <TheweekusSiteWrapper>{children}</TheweekusSiteWrapper>;
    }
    case 'moneyweek': {
      return <MoneyweekSiteWrapper>{children}</MoneyweekSiteWrapper>;
    }
    case 'mozo': {
      return <MozoSiteWrapper>{children}</MozoSiteWrapper>;
    }
    case 'practicalcaravan': {
      return <PracticalcaravanSiteWrapper>{children}</PracticalcaravanSiteWrapper>;
    }
    case 'practicalmotorhome': {
      return <PracticalmotorhomeSiteWrapper>{children}</PracticalmotorhomeSiteWrapper>;
    }
    default: {
      return <DefaultSiteWrapper>{children}</DefaultSiteWrapper>;
    }
  }
};
