import React from 'react';

import type { Event } from '@fie/analytics-library/src/types/Analytics';
import type { Product } from '@fie/analytics-library/src/types/Product';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import getClickType from './getClickType';
import { getUrl } from './getUrl';
import type { IAffiliateLinkProps } from './model';
import styles from './styles/affiliate-link.css';

const AffiliateLink: React.FC<IAffiliateLinkProps> = ({
  deal,
  label,
  clickType,
  linkClick,
  children,
  className,
  customTarget,
  customUrl,
}) => {
  const linkRef = React.useRef<null | HTMLAnchorElement>(null);
  const url = (customUrl || getUrl(deal, label)) ?? '';

  const {
    sendAnalytics,
    translate,
    genericSharedComponents: { TrackedLink },
    articleUrl,
  } = useHawkWidgetContext();

  const trackLinkClick = (event): void => {
    if (sendAnalytics) {
      const gaEvent = {
        clickType: getClickType(deal, articleUrl, clickType),
        prefix: 'Click from',
      } as Event; // TODO: remove retyping after fie-analytics types are checked
      const product = {
        url,
      } as Product;
      // Overwrite the label if it's provided - used by prime shipping 'Free Trial'
      if (label) {
        gaEvent.label = label;
      }

      sendAnalytics(gaEvent, deal, event, product);
    }

    if (linkClick) {
      linkClick(deal);
    }
  };
  const ariaLabelModel =
    deal?.model && deal.model !== 'Everything' ? deal.model : deal?.model_matched?.join(',');

  return (
    <TrackedLink
      trackLinkClick={trackLinkClick}
      customTarget={customTarget}
      attributes={{
        ref: linkRef,
        href: url,
        referrerPolicy: 'no-referrer-when-downgrade',
        className: className ? styles[className] : styles['container'],
        'data-product-key': deal?.product_key,
        'data-url': deal?.offer?.link,
        'data-model-id': deal?.model_id,
        'data-match-id': deal?.id,
        'data-product-type': deal?.product_type,
        'aria-label':
          (ariaLabelModel &&
            deal?.merchant?.name &&
            translate('ariaLabelText', [ariaLabelModel, deal.merchant.name])) ||
          translate('alternativeLinkText'),
      }}
    >
      {children}
    </TrackedLink>
  );
};

export default AffiliateLink;
