import type { EventBattle } from '@fie/analytics-library/src/types/Battle';

import { updateHawk } from '../../../../utils/global';
import { IProcessedWidget } from '../../renderWidget/updateProcessedWidgets';

import { analyticsHawkAppeared } from './analyticsHawkAppeared';
import { ResolveWidgetsConfig } from './model';
import { processWidget } from './processWidget';

export const resolveWidgets = async ({
  unprocessedWidgets,
  platformData,
  siteConfig,
  localiser,
  editorialOnPage,
}: ResolveWidgetsConfig): Promise<void> => {
  const processedWidgetsInfo: IProcessedWidget[] = [];

  for (const unprocessedWidget of unprocessedWidgets) {
    if (unprocessedWidget) {
      const index = unprocessedWidgets.indexOf(unprocessedWidget);
      unprocessedWidget.classList.add('hawk-processed');

      /* Ensure we support old widget placeholders, but check before we change
      the classes to avoid reflow */
      if (unprocessedWidget.classList.contains('hawk-placeholder')) {
        unprocessedWidget.classList.remove('hawk-placeholder');
        unprocessedWidget.classList.add('hawk-nest');
      }

      const processedWidgetInfo = await processWidget({
        element: unprocessedWidget,
        siteConfig,
        articleType: platformData.articleType,
        articleCategory: platformData.articleCategory,
        articleId: platformData.articleId,
        keywords: platformData.keywords,
        territory: platformData.territory,
        platform: platformData.platform,
        articleName: document.title,
        articleUrl: document.location.href,
        localiser,
        editorialOnPage,
        index,
      });

      if (
        processedWidgetInfo &&
        !processedWidgetsInfo.find((widget) => widget.widgetId === processedWidgetInfo?.widgetId)
      ) {
        processedWidgetsInfo.push(processedWidgetInfo);
      }
    }
  }

  // All widgets have loaded, so store truthy value in widgetsLoaded
  updateHawk({
    widgetsLoaded: 1,
  });

  const battle = processedWidgetsInfo.find((widget) => widget?.props?.battle)?.props?.battle;

  // Send an event so Hawk X knows the widgets have been rendered
  analyticsHawkAppeared({
    processedWidgets: processedWidgetsInfo,
    battle: battle as EventBattle,
    platformData,
    siteConfig,
  });
};
