import React from 'react';

import { Response } from '../../../../../assets/types/Response';
import buildUrl from '../../../../../utils/buildUrl';
import { addAirdaleInfo } from '../../../../modules/setupModelWidget/getAirdaleInfo';
import type { SiteWithPlatform } from '../../../../modules/sites/types/Site';
import type { APIData } from '../../../../types/APIData';
import { DealData } from '../../../../types/DealData';
import { RequestType, ResponseType } from '../../../../types/GetJSON';

import { getDataFilters } from './getDataFilters';
import { ActionTypes } from './model';
import type { Action, Actions } from './model';

export const getActions = (dispatch: React.Dispatch<Action>): Actions => {
  const scrollLeft: Actions['scrollLeft'] = () => {
    dispatch({ type: ActionTypes.SCROLL_LEFT });
  };

  const scrollRight: Actions['scrollRight'] = () => {
    dispatch({ type: ActionTypes.SCROLL_RIGHT });
  };

  const changeTab: Actions['changeTab'] = (event) => {
    dispatch({ type: ActionTypes.CHANGE_TAB, payload: event });
  };

  const setCurrentPage: Actions['setCurrentPage'] = (currentPage) => {
    dispatch({
      type: ActionTypes.SET_CURRENT_PAGE,
      payload: currentPage,
    });
  };

  const loadMoreDeals: Actions['loadMoreDeals'] = (sendGa) => {
    dispatch({
      type: ActionTypes.LOAD_MORE_DEALS,
      payload: sendGa,
    });
  };

  const showFewerDeals: Actions['showFewerDeals'] = () => {
    dispatch({
      type: ActionTypes.SHOW_FEWER_DEALS,
    });
  };

  const changeFilter: Actions['changeFilter'] = (event) => {
    dispatch({
      type: ActionTypes.CHANGE_FILTER,
      payload: event,
    });
  };

  const changeRangeFilter: Actions['changeRangeFilter'] = (filterKey, filterValue) => {
    dispatch({
      type: ActionTypes.CHANGE_RANGE_FILTER,
      payload: {
        filterKey,
        filterValue,
      },
    });
  };

  const changeSort: Actions['changeSort'] = (event) => {
    dispatch({
      type: ActionTypes.CHANGE_SORT,
      payload: event,
    });
  };

  const toggleCheckbox: Actions['toggleCheckbox'] = (event) => {
    dispatch({
      type: ActionTypes.TOGGLE_CHECKBOX,
      payload: event,
    });
  };

  const changeCheckboxGroup: Actions['changeCheckboxGroup'] = (event) => {
    dispatch({
      type: ActionTypes.CHANGE_CHECKBOX_GROUP,
      payload: event,
    });
  };

  const changeRadioButtonGroup: Actions['changeRadioButtonGroup'] = (event) => {
    dispatch({
      type: ActionTypes.CHANGE_RADIO_BUTTON_GROUP,
      payload: event,
    });
  };

  const updateInputValue: Actions['updateInputValue'] = (event) => {
    dispatch({
      type: ActionTypes.UPDATE_INPUT_VALUE,
      payload: event,
    });
  };

  const updateInputPostcode: Actions['updateInputPostcode'] = (event) => {
    dispatch({
      type: ActionTypes.UPDATE_INPUT_POSTCODE,
      payload: event,
    });
  };

  const submitInput: Actions['submitInput'] = (event) => {
    dispatch({
      type: ActionTypes.SUBMIT_INPUT,
      payload: event,
    });
  };

  const submitPostcode: Actions['submitPostcode'] = (event) => {
    dispatch({
      type: ActionTypes.SUBMIT_POSTCODE,
      payload: event,
    });
  };

  const clearFilters: Actions['clearFilters'] = () => {
    dispatch({
      type: ActionTypes.CLEAR_FILTERS,
    });
  };

  const changePage: Actions['changePage'] = (event) => {
    dispatch({
      type: ActionTypes.CHANGE_PAGE,
      payload: event,
    });
  };

  const loadParentModel: Actions['loadParentModel'] = (parentModel) => {
    dispatch({
      type: ActionTypes.LOAD_PARENT_MODEL,
      payload: parentModel,
    });
  };

  const updateModelSuggestions: Actions['updateModelSuggestions'] = (suggestion) => {
    dispatch({
      type: ActionTypes.UPDATE_MODEL_SUGGESTIONS,
      payload: suggestion,
    });
  };

  const getData: Actions['getData'] = async (
    params,
    appendOffers,
    widgetEndpoint,
    getJSON,
    state,
    site,
  ) => {
    const defaultData = {
      offers: [],
      filters: [],
    };

    try {
      const { body } = (await getJSON(widgetEndpoint, params)) as unknown as Response<
        ResponseType<RequestType.WIDGET_REQUEST>
      >;
      const { widget } = body;

      // If response does contain any data - this should never happen

      const responseData = widget?.data ?? { offers: [], filters: [] };

      const data = {
        ...state.data,
        [state.activeTab?.value]: {
          ...state.data[state.activeTab?.value],
          ...responseData,
          offers: appendOffers
            ? (state.data[state.activeTab?.value]?.offers || []).concat(responseData.offers)
            : responseData.offers,
          filters: getDataFilters(
            state.data[state.activeTab?.value]?.filters || [],
            responseData.filters,
          ),
        },
      };

      const dealData = await state.getDealData(data[state.activeTab.value]);
      const dealDataWithAirdale = await addAirdaleInfo(
        dealData,
        site || ({} as SiteWithPlatform),
        getJSON,
      );

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data, models: body.models, dealData: dealDataWithAirdale },
      });

      return data;
    } catch (e) {
      // The request failed - this should never happen
      // We do set empty values to change UI and in such manner inform user about failing
      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data: { offers: [], filters: [] }, models: [], dealData: {} as DealData },
      });

      // TODO: this should be removed with APIData type refactor
      return defaultData as unknown as APIData;
    }
  };

  const postData: Actions['postData'] = async (
    params,
    appendOffers,
    widgetEndpoint,
    getJSON,
    state,
    site,
  ) => {
    const defaultData = {
      offers: [],
      filters: [],
    };

    try {
      const { postcode, ...restParams } = params;
      const formData = new FormData();
      if (postcode && postcode !== '') {
        formData.append('postcode', postcode as string);
      }

      const responseRaw = await fetch(buildUrl(widgetEndpoint as string, restParams), {
        method: 'POST',
        body: formData,
      });

      const body = await responseRaw.json();
      const { widget } = body;

      // If response does contain any data - this should never happen

      const responseData = widget?.data ?? { offers: [], filters: [] };

      const data = {
        ...state.data,
        [state.activeTab?.value]: {
          ...state.data[state.activeTab?.value],
          ...responseData,
          offers: appendOffers
            ? (state.data[state.activeTab?.value]?.offers || []).concat(responseData.offers)
            : responseData.offers,
          filters: getDataFilters(
            state.data[state.activeTab?.value]?.filters || [],
            responseData.filters,
          ),
        },
      };

      const dealData = await state.getDealData(data[state.activeTab.value]);
      const dealDataWithAirdale = await addAirdaleInfo(
        dealData,
        site || ({} as SiteWithPlatform),
        getJSON,
      );

      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data, models: body.models, dealData: dealDataWithAirdale },
      });

      return data;
    } catch (e) {
      // The request failed - this should never happen
      // We do set empty values to change UI and in such manner inform user about failing
      dispatch({
        type: ActionTypes.UPDATE_DATA,
        payload: { data: { offers: [], filters: [] }, models: [], dealData: {} as DealData },
      });

      // TODO: this should be removed with APIData type refactor
      return defaultData as unknown as APIData;
    }
  };

  const sortSimilarAtLastPosition: Actions['sortSimilarAtLastPosition'] = (data) => {
    dispatch({
      type: ActionTypes.SORT_SIMILAR_TO_LAST_POSITION,
      payload: { data },
    });
  };

  return React.useMemo<Actions>(
    () => ({
      scrollLeft,
      scrollRight,
      changeTab,
      setCurrentPage,
      loadMoreDeals,
      showFewerDeals,
      changeFilter,
      changeRangeFilter,
      changeSort,
      toggleCheckbox,
      changeCheckboxGroup,
      changeRadioButtonGroup,
      updateInputValue,
      updateInputPostcode,
      submitInput,
      submitPostcode,
      clearFilters,
      changePage,
      loadParentModel,
      updateModelSuggestions,
      getData,
      postData,
      sortSimilarAtLastPosition,
    }),
    [],
  );
};
