import type { Event } from '@fie/analytics-library/src/types/Analytics';
import type { Product } from '@fie/analytics-library/src/types/Product';

import type { DealData } from '../../types/DealData';
import { WidgetId } from '../../types/Widget';

import { WidgetResult, ApiCallResult } from './model';
import type { IPostProcessActions, IPostProcessData } from './model';

export default ({
  placeholder,
  widgetId,
  sendAnalytics,
  setDealData,
  widgetTypeComponent,
  sendViewedEvent,
  dealData,
  data,
  type,
}: IPostProcessActions & IPostProcessData): void => {
  // Add widgetId to the placeholder
  if (widgetId && placeholder) {
    placeholder.setAttribute('data-widget-id', `${widgetId}`);
  }

  // Use data to determine what ga event should be sent (appear/missing/preserved),
  // defaulting to considering the widget to have appeared
  const result: WidgetResult = ((): WidgetResult => {
    if (type === WidgetResult.SKIPPED) {
      return WidgetResult.SKIPPED;
    }
    if (type === ApiCallResult.SUCCESS) {
      return WidgetResult.RENDERED;
    }
    if (type === WidgetResult.PRESERVED) {
      return WidgetResult.PRESERVED;
    }
    return WidgetResult.MISSING;
  })();

  /* Send the appear/missing/preserved event for all widgets, but only if the
  widget has rendered for the seasonal widget */
  const skipTracking = widgetTypeComponent === WidgetId.SEASONAL && result !== 'rendered';

  // Send GA event based on the result (appeared/missing/preserved)
  // and setup viewport tracking for rendered widgets
  // Check there is no data-result to ensure we don't
  // send the appear again when the widget is viewed
  let gaEvent = {};
  let gaProduct = {};
  if (widgetTypeComponent === WidgetId.SEASONAL) {
    gaEvent = {
      category: 'Navigation',
      label: data && data.link_text,
    };
    gaProduct = {
      url: data && data.url,
    };
  }

  const isGaProductEmpty = Object.keys(gaProduct).length === 0;

  // Send the viewed event
  if (!skipTracking && sendViewedEvent) {
    sendAnalytics(
      {
        ...(gaEvent as Event),
        type: 'viewed',
        // clickType is considered as eventType here
        clickType: result === 'rendered' ? 'appeared' : result,
      },
      null,
      null,
      isGaProductEmpty ? null : (gaProduct as Product),
    );
  }

  setDealData(dealData ?? ({} as DealData));

  // Set the result on the placeholder for integration tests and debugging
  if (placeholder) {
    placeholder.setAttribute('data-result', result);
  }
};
