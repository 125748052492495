import AffiliateLink from '../GenericComponents/AffiliateLink/AffiliateLink';
import AllDeals from '../GenericComponents/AllDeals/AllDeals';
import Animate from '../GenericComponents/Animate/Animate';
import Button from '../GenericComponents/Button/Button';
import DealImage from '../GenericComponents/DealImage/DealImage';
import DisplayName from '../GenericComponents/DisplayName/DisplayName';
import DisplayPrice from '../GenericComponents/DisplayPrice/DisplayPrice';
import GridItems from '../GenericComponents/GridItems/GridItems';
import HawkTabs from '../GenericComponents/HawkTabs/HawkTabs';
import { HawkWidgetAdvanced } from '../GenericComponents/HawkWidget/HawkWidgetAdvanced';
import HawkWidgetProvider from '../GenericComponents/HawkWidget/HawkWidgetProvider';
import HawkWidgetStandard from '../GenericComponents/HawkWidget/HawkWidgetStandard';
import LazyImage from '../GenericComponents/LazyLoad/LazyImage';
import LoadMore from '../GenericComponents/LoadMore/LoadMore';
import Main from '../GenericComponents/Main/Main';
import MainPrice from '../GenericComponents/MainPrice/MainPrice';
import ModelCodeReviews from '../GenericComponents/ModelCodeReviews/ModelCodeReviews';
import PriceNote from '../GenericComponents/PriceNote/PriceNote';
import Spinner from '../GenericComponents/Spinner/Spinner';
import Title from '../GenericComponents/Title/Title';
import TrackedLink from '../GenericComponents/TrackedLink/TrackedLink';
import TrackingPixel from '../GenericComponents/TrackingPixel/TrackingPixel';

export const genericSharedComponents = {
  AffiliateLink,
  AllDeals,
  Animate,
  Button,
  GridItems,
  HawkTabs,
  HawkWidgetAdvanced,
  HawkWidgetProvider,
  HawkWidgetStandard,
  LazyImage,
  LoadMore,
  Main,
  MainPrice,
  ModelCodeReviews,
  DealImage,
  DisplayName,
  DisplayPrice,
  PriceNote,
  Spinner,
  Title,
  TrackedLink,
  TrackingPixel,
};
