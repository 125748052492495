import type { Deal } from '../../../types/Deal';
import type { DealData } from '../../../types/DealData';
import type { getJSONFn, RequestType } from '../../../types/GetJSON';
import type { SiteWithPlatform } from '../../sites/types/Site';

import { sanitiseAiredaleStringParam } from './sanitiseAiredaleStringParam';

export const addAirdaleInfo = async (
  dealData: DealData,
  site: SiteWithPlatform,
  getJSON: getJSONFn<RequestType.AIRDALE_REQUEST>,
): Promise<DealData> => {
  const retailers = [
    ...new Set(
      dealData.deals
        .map((deal) => {
          if (deal.merchant.name) {
            return sanitiseAiredaleStringParam(deal.merchant.name);
          }
          return deal.merchant.name;
        })
        .flat(),
    ),
  ];

  const MIN_AIREDALE_RESULTS = 100;
  const MAX_AIREDALE_RESULTS = retailers.length * 3;
  const params = {
    site: site.vanillaName ?? site.value,
    nb_results: Math.max(MIN_AIREDALE_RESULTS, MAX_AIREDALE_RESULTS),
    articleVoucherRetailerCategory: retailers.join(','),
    fl: 'articleVoucherRetailerCategory,canonical',
  };
  const airedaleResponse = await getJSON(process.env.AIRDALE_ENDPOINT || '', params);

  return {
    ...dealData,
    deals: dealData.deals.map((deal: Deal) => ({
      ...deal,
      airdale_info: {
        canonicalUrl: airedaleResponse?.body?.find(
          (siteInfo) =>
            siteInfo.articlevoucherretailercategory?.toLowerCase() ===
            deal.merchant.name?.toLowerCase(),
        )?.canonical,
      },
    })),
  };
};
