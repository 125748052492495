import { getAirdaleParams } from '../../../../sharedModules/getAirdaleParams';
import type { RequestType, getJSONFn } from '../../../types/GetJSON';
import { ModelWidgetData } from '../types';

import { sanitiseAiredaleStringParam } from './sanitiseAiredaleStringParam';
import { shouldFetchAirdaleInfo } from './shouldFetchAirdaleInfo';

export const getAirdaleInfo = async (modelWidget: ModelWidgetData): Promise<ModelWidgetData> => {
  if (
    !shouldFetchAirdaleInfo(
      modelWidget.options.props.widgetType,
      Boolean(modelWidget.options.props.params.filter_merchant_name),
    )
  ) {
    return modelWidget;
  }

  const retailers = [
    ...new Set(
      modelWidget.model.tabConfigs
        .map((tab) => {
          return modelWidget.model.tabData[tab.value].offers.map((offer) => {
            if (offer.merchant.name) {
              return sanitiseAiredaleStringParam(offer.merchant.name);
            }
            return offer.merchant.name;
          });
        })
        .flat(),
    ),
  ];

  if (!retailers.length) {
    return modelWidget;
  }

  const MIN_AIREDALE_RESULTS = 100;
  const MAX_AIREDALE_RESULTS = retailers.length * 3;
  const params = getAirdaleParams(
    modelWidget.options.props.site.vanillaName ?? modelWidget.options.props.site.value,
    Math.max(MIN_AIREDALE_RESULTS, MAX_AIREDALE_RESULTS),
    retailers.join(','),
    modelWidget.options.props.territory,
  );

  try {
    const { getJSON }: { getJSON: getJSONFn<RequestType.AIRDALE_REQUEST> } =
      modelWidget.options.props;
    const airdaleData = await getJSON(process.env.AIRDALE_ENDPOINT || '', params);

    return {
      ...modelWidget,
      model: {
        ...modelWidget.model,
        tabData: {
          ...modelWidget.model.tabConfigs.reduce((acc, tab) => {
            return {
              ...acc,
              [tab.value]: {
                ...modelWidget.model.tabData[tab.value],
                offers: modelWidget.model.tabData[tab.value].offers.map((offer) => {
                  return {
                    ...offer,
                    airdale_info: {
                      canonicalUrl: airdaleData.body.find(
                        (siteInfo) =>
                          siteInfo.articlevoucherretailercategory?.toLowerCase() ===
                          offer.merchant.name?.toLowerCase(),
                      )?.canonical,
                    },
                  };
                }),
              },
            };
          }, {}),
        },
        airdaleData,
      },
    };
  } catch (_) {
    return modelWidget;
  }
};
