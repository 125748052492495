import React from 'react';

import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import DisplayPriceCode from './DisplayPriceCode';
import DisplayPriceContractType from './DisplayPriceContractType';
import { getPrice, getFormattedPrice } from './getPrice';
import styles from './styles/display-price.css';

interface DisplayPriceProps {
  deal: Deal;
  className?: string;
  type?: string;
  label?: string;
  showContractType?: boolean;
  showOfferLabel?: boolean;
  forceAppear?: boolean;
  clickType?: string;
  wrapInLink?: boolean;
  useCostFreeText?: boolean;
}

const DisplayPrice: React.FC<DisplayPriceProps> = ({
  className,
  showOfferLabel,
  forceAppear,
  deal,
  type,
  label,
  showContractType,
  wrapInLink = true,
  clickType,
  useCostFreeText = false,
}) => {
  const {
    translate,
    language,
    showPrice,
    showWasPrice,
    showPriceRounded,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  deal = deal || {};
  // Use the passed type. If not passed use monthly (if a contract) or retail (upfront price)
  type = type || getPriceType(deal);
  label = label || '';
  showContractType =
    typeof showContractType === 'undefined'
      ? ['monthly', 'yearly'].indexOf(type) >= 0
      : showContractType;

  // Determine the price to display based on the passed type
  const containerStyle = className && styles[className] ? className : 'container';
  const price = getPrice(deal, type);
  const formattedPrice = getFormattedPrice(
    price,
    deal,
    showOfferLabel,
    type,
    language,
    translate,
    showPriceRounded,
    useCostFreeText,
  );

  /*
    Show the price if showPrice is enabled, and we have a price
    Show a wasPrice if showWasPrice has a valid value, showPrice is enabled and we have a price
  */
  if (
    (showPrice === 'showPrice' ||
      showPrice === 'stream' ||
      showPrice === 'watchAt' ||
      forceAppear) &&
    formattedPrice &&
    (type !== 'wasPrice' ||
      (type === 'wasPrice' &&
        ['price', 'allPercentage', 'allSaving', 'percentagePrice'].indexOf(showWasPrice) >= 0))
  ) {
    const mainComponent = (
      <span
        className={styles[containerStyle]}
        data-type={deal?.offer?.label && showOfferLabel ? 'buySimilar' : type}
      >
        {' '}
        {label && <span className={styles['label']}>{label}</span>}
        <span className={styles['price']}>
          {price !== 0 && (
            <DisplayPriceCode className={className} currencyIso={deal?.offer?.currency_iso} />
          )}
          {formattedPrice}
        </span>
        {showContractType && (
          <DisplayPriceContractType deal={deal} type={type} className={className} />
        )}
      </span>
    );

    return wrapInLink ? (
      <AffiliateLink deal={deal} clickType={clickType} className={className}>
        {mainComponent}
      </AffiliateLink>
    ) : (
      mainComponent
    );
  }

  return null;
};

export default DisplayPrice;
