import React from 'react';

import getButtonText from '../../../modules/getButtonText';
import type { Deal } from '../../../types/Deal';
import { ArrowRight } from '../ArrowRight';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

interface ButtonProps {
  deal: Deal;
  displayMerchant?: boolean;
  className?: string;
  buttonText?: string;
  clickType?: string;
}

const Button: React.FC<ButtonProps> = ({
  deal,
  displayMerchant,
  className,
  buttonText,
  clickType,
}) => {
  const {
    buttonType,
    translate,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  return (
    <AffiliateLink
      {...{
        deal,
        ...(buttonType !== 'chevron' ? { className: className || 'button' } : {}),
        clickType,
        // We want to track exact model with click
        label: deal?.model_matched?.join(','),
      }}
    >
      {buttonType === 'chevron' ? (
        <ArrowRight />
      ) : (
        buttonText || getButtonText(deal, displayMerchant, translate)
      )}
    </AffiliateLink>
  );
};

export default Button;
