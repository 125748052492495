import React from 'react';

import classNames from 'classnames';

import type { Event } from '@fie/analytics-library/src/types/Analytics';

import { PromoType, getPromoItems } from '../../../../sharedModules/getPromoItems';
import type { Deal } from '../../../types/Deal';
import { DealCountdown } from '../DealCountdown';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { Promos } from '../Promos/loadable';

import GridItemMain from './GridItemMain';
import GridItemToggle from './GridItemToggle';
import { GridItemsClassName } from './model';
import styles from './styles/grid-item.css';

interface GridItemProps {
  deal: Deal;
  className: GridItemsClassName;
  index?: number;
  children: (deal: Deal, index?: number) => JSX.Element;
  expandedChildren?: (deal: Deal, index?: number) => JSX.Element;
  stacked?: boolean;
  showPromos?: string;
  showSpacer?: boolean;
  showCountdown?: string;
  widgetName: string;
}

const GridItem: React.FC<GridItemProps> = (props) => {
  const {
    showPromos,
    className,
    index,
    children,
    showSpacer,
    expandedChildren,
    stacked,
    deal,
    widgetName,
  } = props;
  let { showCountdown } = props;
  const { sendAnalytics, dealData, activeTab, loadMore } = useHawkWidgetContext();
  const [expanded, toggleExpandedState] = React.useState(false);

  const moreDeals =
    dealData?.totalDeals > activeTab?.pageSize && loadMore
      ? dealData?.totalDeals > (dealData?.deals || []).length
      : false;

  if (children) {
    showCountdown = deal?.end_time ? showCountdown : '';

    const toggleExpanded = (): void => {
      if (sendAnalytics && deal) {
        sendAnalytics(
          {
            label: expanded ? 'Toggle Closed' : 'Toggle Opened',
            clickType: 'Row Toggle',
            prefix: 'Click from',
          } as Event,
          deal,
        );
      }

      toggleExpandedState((prevValue) => !prevValue);
    };

    const getToggleExpanded = (expandedChildren): (() => void) | undefined => {
      if (expandedChildren) {
        return toggleExpanded;
      }

      return undefined;
    };

    const promoItems = getPromoItems(deal, PromoType.ALLOWED, false);

    const promosGrid = promoItems.length > 0 && (
      <GridItemMain deal={deal} className={className} stacked={stacked} type="promos">
        <>
          <Promos key="promos" items={promoItems} className={widgetName || 'promos'} />
        </>
      </GridItemMain>
    );
    const countdownGrid = (
      <GridItemMain deal={deal} className={className} stacked={stacked}>
        <>
          <DealCountdown key="countdown" deal={deal} className={widgetName} />
        </>
      </GridItemMain>
    );

    return (
      <div
        className={classNames(styles['container'], className.gridItem)}
        data-expandable={expandedChildren ? 1 : 0}
        data-stacked={stacked ? 1 : 0}
        data-load-more={moreDeals}
        onClick={getToggleExpanded(expandedChildren)}
        role="button"
        tabIndex={0}
        onKeyDown={getToggleExpanded(expandedChildren)}
      >
        {showPromos === 'above' && promosGrid}
        {showCountdown === 'above' && countdownGrid}
        <GridItemMain deal={deal} stacked={stacked} className={className}>
          {children(deal, index)}
        </GridItemMain>
        {showPromos === 'below' && promosGrid}
        {showCountdown === 'below' && countdownGrid}
        {expandedChildren && (
          <GridItemMain deal={deal} className={className} stacked={stacked}>
            <>
              <div onClick={toggleExpanded} role="button" tabIndex={0} onKeyDown={toggleExpanded}>
                <GridItemToggle expanded={expanded} toggleExpanded={toggleExpanded} />
              </div>
            </>
          </GridItemMain>
        )}
        {expanded && expandedChildren && (
          <div className={styles.expanded}>
            <GridItemMain deal={deal} stacked={stacked} expanded={true} className={className}>
              {expandedChildren(deal, index)}
            </GridItemMain>
          </div>
        )}
        {showSpacer && <div className={styles.spacer} />}
      </div>
    );
  }
  return null;
};

export default GridItem;
