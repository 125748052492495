import type { ParametersType, RequestTypeName, ServerParametersType } from '../core/types/GetJSON';

/**
 * Replace accents with their english equivalent so we look up the correct model -
 * mysql alias creation for accent fails as the character is considered to match the model name
 * HAWK-6229
 * Shamelessly stolen from https://gist.github.com/alisterlf/3490957
 */
const replaceAccents = (string: unknown): string => {
  /* Ensure the passed string is actually a string
  - e.g. rows=3 (value in params object in default export is a number) */
  const strAccents = String(string).split('');
  const strAccentsLen = strAccents.length;
  const strAccentsOut: string[] = [];
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  return strAccentsOut.join('');
};

/**
 * Sanitize the value for the url by replacing accents with English equivalent
 * and encoding the value
 */
const sanitizeValue = (value: unknown): string => {
  const result = replaceAccents(value);
  const encodedResult = encodeURIComponent(result);
  return encodedResult;
};

/**
 * Build the URL form a base URL and the query parameters.
 *
 * Make sure the query parameters are encoded, so that for instance model
 * name "Allen & Heath Qu-SB" will have the ampersand encoded when it's sent
 * to the API. This will work fine for multi-model requests, or multi-value
 * filters, because even though the comma will be encoded, the API will treat
 * this correctly.
 *
 * Also used to generate the all deals link in AllDeals.js react component
 */
export default <T extends RequestTypeName>(
  url: string,
  params: ParametersType<T> | ServerParametersType<T> | Record<string, unknown>,
): string => {
  url = url || '';
  params = params || {};

  /* Remove empty params but not if they are a number - e.g. sending
  distinct_networks=0 is value (HAWK-7230) */
  Object.keys(params).forEach((param) => {
    if (!params[param] && typeof params[param] !== 'number') {
      delete params[param];
    }
  });

  const cUrl = url;
  Object.keys(params).forEach((key) => {
    // Only return the param if it is not empty
    if (typeof params[key] !== 'undefined') {
      if (key !== '#') {
        if (params[key] instanceof Array) {
          Object.keys(params[key]).forEach((i) => {
            url += `${(cUrl === url ? '?' : '&') + key}[]=${sanitizeValue(params[key][i])}`;
          });
        } else {
          url += `${(cUrl === url ? '?' : '&') + key}=${sanitizeValue(params[key])}`;
        }
      } else {
        url += `${key + sanitizeValue(params[key])}`;
      }
    }
  });

  return url;
};
