import type { ParametersType, RequestTypeName, ResponseType } from '../../core/types/GetJSON';
import buildUrl from '../../utils/buildUrl';
import type { Response } from '../types/Response';

import { getNodes } from './getNodes';

/**
 * CORS AJAX get JSON, no jQuery
 */
export const getJSON = async <T extends RequestTypeName>(
  url: string,
  params: ParametersType<T> | Record<string, never>,
): Promise<Response<ResponseType<T>>> => {
  if (typeof fetch !== 'undefined') {
    // Send the request
    const response = await fetch(buildUrl<T>(url, params));
    if (response && response.json) {
      return {
        body: await response.json(),
        responseTime: 0,
      };
    }
    return {
      body: {},
      responseTime: 0,
    };
  }
  return {
    body: {},
    responseTime: 0,
  };
};

/**
 *
 * @param {string} filename
 */
export const getStylesheet = (filename: string): void => {
  const stylesheetExists =
    document.querySelectorAll(`link[rel="stylesheet"][href="${filename}"]`).length > 0;

  if (stylesheetExists) {
    return;
  }
  let rafExt;
  if (typeof requestAnimationFrame !== 'undefined') {
    rafExt = requestAnimationFrame;
  } else if (typeof mozRequestAnimationFrame !== 'undefined') {
    rafExt = mozRequestAnimationFrame;
  } else if (typeof webkitRequestAnimationFrame !== 'undefined') {
    rafExt = webkitRequestAnimationFrame;
  } else if (typeof msRequestAnimationFrame !== 'undefined') {
    rafExt = msRequestAnimationFrame;
  }

  const loadAssets = async (): Promise<void> => {
    const head = await getNodes({
      selector: 'head',
    });
    if (head.length > 0) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = filename;
      head[0].appendChild(link);
    }
  };

  if (rafExt) {
    rafExt(loadAssets);
  } else {
    window.addEventListener('load', loadAssets);
  }
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @returns {Promise.<object>}
 */
export const postJSON = async (
  url: string,
  data: Record<string, string | number | null | undefined>,
): Promise<Record<string, string>> => {
  // Send the request
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({ 'Content-type': 'application/json' }),
    });

    return await response.json();
  } catch (e) {
    return {};
  }
};
