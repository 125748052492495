import type { EventBattle } from '@fie/analytics-library/src/types/Battle';

import type Localiser from '../../../../core/modules/Localiser';
import type { Site } from '../../../../core/modules/sites/types/Site';
import type { PlatformData } from '../../../../core/types/PlatformData';
import type { IProcessedWidget } from '../../renderWidget/updateProcessedWidgets';

export interface ProcessCallbackConfig {
  element: HTMLElement;
  siteConfig: Site;
  platformData: PlatformData;
  localiser: Localiser;
  editorialOnPage: boolean;
}

export interface BuildCallbackConfig {
  element: HTMLElement;
}

export interface AnalyticsHawkAppeared {
  processedWidgets: IProcessedWidget[];
  battle: EventBattle;
  platformData: PlatformData;
  siteConfig: Site;
}

export interface UnbuiltPlaceholders {
  elements: Array<HTMLElement | null>;
  callbackToBuildPlaceholder: (config: BuildCallbackConfig) => Promise<Array<HTMLElement | null>>;
}

// callbackToProcess: (config: ProcessCallbackConfig) => Promise<Array<IProcessedWidget | null>>;

export enum HawkEvents {
  HAWK_PROCESS_NOVA_WIDGET = 'HAWK_PROCESS_NOVA_WIDGET',
  HAWK_PROCESS_ARTICLE = 'processArticle',
}

export interface ResolveWidgetsConfig {
  siteConfig: Site;
  platformData: PlatformData;
  unprocessedWidgets: Array<HTMLElement | null>;
  localiser: Localiser;
  editorialOnPage: boolean;
}
