import React from 'react';

import classNames from 'classnames';

import formatContractLength from '../../../modules/formatContractLength';
import { getDisplayName } from '../../../modules/getDisplay';
import getShortName from '../../../modules/getShortName';
import type { Deal } from '../../../types/Deal';
import type { ModelExtra } from '../../../types/ModelInfo';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/display-name.css';

interface DisplayNameProps {
  deal: Deal;
  className?: string;
  type?: string;
  expandable?: boolean;
  tag?: keyof JSX.IntrinsicElements;
}

const DisplayName: React.FC<DisplayNameProps> = ({
  deal,
  className,
  type,
  expandable: defaultExpandable,
  tag = 'div',
}) => {
  const {
    translate,
    dealData,
    displayNameType,
    displayNameExpandable,
    displayNameMax,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  const getName = (): string => {
    const modelInfo =
      deal?.model_id && dealData?.modelInfo && dealData.modelInfo[deal.model_id]
        ? dealData.modelInfo[deal.model_id]
        : ({} as ModelExtra);
    const typeToUse = type || displayNameType;

    // Use the 'View Similar Label' for similar products instead of the real model name
    if (
      deal?.product_type &&
      deal.product_type === 200 &&
      ['model', 'fullName'].includes(typeToUse)
    ) {
      return translate('viewSimilarLabel');
    }

    // Use the type prop in preference, to allow us to override the default feature
    const brandLabel = (deal?.labels_formatted || []).find((l) => l.type === 'brand');
    switch (typeToUse) {
      case 'fullName':
        return getDisplayName(deal);
      case 'product':
        return deal?.offer?.name;
      case 'model':
        return modelInfo?.model_name;
      case 'modelWithBrand':
        return deal?.model;
      case 'contractLength':
        return formatContractLength(deal, translate);
      case 'brandMerchant':
        return (
          (brandLabel ? brandLabel.display_value : modelInfo.brand || deal?.merchant.name) ?? ''
        );
      default:
        return '';
    }
  };

  const getMax = (): number => {
    if (displayNameMax) {
      return displayNameMax;
    }
    return 40;
  };

  const name = getShortName(getName(), getMax());
  const fullName = getName();
  const expandable = defaultExpandable || displayNameExpandable;

  const Tag = tag;

  return (
    <AffiliateLink deal={deal}>
      <Tag className={classNames(styles['container'], className)}>
        <>
          {name}
          {expandable && name.length !== fullName.length && '...'}
          {expandable && name.length !== fullName.length && (
            <span className={styles['full']}>{fullName}</span>
          )}
        </>
      </Tag>
    </AffiliateLink>
  );
};

export default DisplayName;
