// Disable any warning: we can story any variable in hawk window property
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { FastifyInstance } from 'fastify';

import type { processWidget } from '../assets/modules/hawk/processWidgets/processWidget';
import type { renderWidget } from '../assets/modules/renderWidget';
import type { IProcessedWidget } from '../assets/modules/renderWidget/updateProcessedWidgets';
import type Feature from '../core/modules/features/types/Feature';
import type Localiser from '../core/modules/Localiser';
import type { Site, SiteWithPlatform } from '../core/modules/sites/types/Site';
import type { BattleDealOverrides } from '../core/types/BattleDealOverrides';
import type { Translate } from '../core/types/Localiser';
import type { Widget } from '../core/types/Widget';

export interface HawkWindowType {
  widgetId: string;
  customTrackingId: string;
  cache: Record<string, any>;
  renderWidget: typeof renderWidget;
  widgetMap: Widget[];
  widgetsLoading: number;
  widgetFeatures: Feature[];
  sites: SiteWithPlatform[];
  localiser: Localiser;
  territory: string;
  skipGA: boolean;
  version?: string;
  processWidget: typeof processWidget;
  articleId: string | null;
  articleType: string | null;
  articleCategory: string | null;
  keywords: string[] | null;
  platform: string;
  articleName: string;
  articleUrl: string;
  site: Site;
  editorialOnPage: boolean;
  processedWidgets: IProcessedWidget[];
  gaCache: Record<string, string | number | null>;
  blockEditorWidgets: boolean;
  app: FastifyInstance;
  widgetsLoaded: number;
  overrides: BattleDealOverrides[];
  translate: Translate;
}

type ScopeType = ((Window & typeof globalThis) | typeof globalThis | Record<string, never>) & {
  hawk: HawkWindowType;
};

const getScope = (): (Window & typeof globalThis) | typeof globalThis | Record<string, never> => {
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  return {};
};
/**
 * Setup window.hawk
 */
const setHawk = (): HawkWindowType => {
  const scope: ScopeType = getScope() as ScopeType;
  if (!scope.hawk) {
    scope.hawk = {};
  }
  return scope.hawk;
};

/**
 * get the window.hawk object
 */
export const getHawk = (): HawkWindowType => {
  return setHawk();
};

/**
 * Update the window hawk object - avoids needing to keep checking if it exists
 */
export const updateHawk = (params: Partial<HawkWindowType>): void => {
  setHawk();
  const scope: ScopeType = getScope() as ScopeType;
  Object.assign(scope.hawk, params);
};
